import styled, { CSSObject } from '@emotion/styled'
import { baseColors } from '@totmoney/colors'
import { breakpoints, mq } from '@totmoney/grid'

import Grid from 'components/Grid'
import { H2, Paragraph, RichText } from 'components/Typography'
import { LinkInfo } from 'components/LinkComponent'
import Stack from 'components/Stack'
import availableLogos, { AvailableLogos } from 'components/logos'
import Image from 'next/image'
import { LinkTrackEvent } from 'helpers/events/components/LinkTrackEvent'

const ButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1.5rem',

  [mq.xs]: {
    flexDirection: 'row',
    gap: '2.5rem'
  }
})

const LogosWrapper = styled.div({
  display: 'flex',
  gap: '1.5rem',
  marginTop: '1rem',
  padding: '0.75rem 0'
})

export type ImageInfo = {
  description: string
  url: string
  width?: string | number
  height?: string | number
}

const StyledLinkTrackEvent = styled(LinkTrackEvent)({ margin: 0 })

export interface MainComponentProps {
  name: string
  subtitle?: string
  title: string
  text: React.ReactNode
  logos?: AvailableLogos[]
  links?: (LinkInfo | null)[]
  linksAsButtons?: boolean
  image: ImageInfo
  hideImageOnMobile?: boolean
  imageFirst?: boolean
  imageMinHeight?: CSSObject['minHeight']
}

const MainComponent = ({
  subtitle,
  title,
  text,
  logos,
  links,
  linksAsButtons,
  image,
  hideImageOnMobile,
  imageFirst,
  name,
  imageMinHeight
}: MainComponentProps) => {
  const imageContainerStyle: CSSObject = {
    position: 'relative',
    height: '100%',
    display: hideImageOnMobile ? 'none' : 'block',
    gridColumnEnd: 'span 4',
    ...(imageMinHeight && { minHeight: imageMinHeight }),
    [mq.xs]: {
      gridColumnEnd: 'span 6',
      gridColumnStart: imageFirst ? 1 : 7,
      gridRowStart: 1,
      display: 'block'
    }
  }

  const textContainerStyle = {
    gridColumnEnd: 'span 4',
    [mq.xs]: { gridColumnEnd: 'span 5', gridColumnStart: imageFirst ? 8 : 1, gridRowStart: 1 }
  }

  return (
    <Grid css={{ alignItems: 'center', rowGap: '2rem' }}>
      <div css={textContainerStyle}>
        <Stack>
          <div>
            <Paragraph as='h2' weight='bold' css={{ color: baseColors.liliac[700] }}>
              {subtitle}
            </Paragraph>
            <H2 as='p' css={{ paddingTop: '1rem' }}>
              {title}
            </H2>
          </div>
          <div>
            <RichText>{text}</RichText>
            {logos ? (
              <LogosWrapper>
                {logos.map((logo) => {
                  const LogoElement = availableLogos[logo]
                  return <LogoElement key={logo} fill={baseColors.neutral[500]} />
                })}
              </LogosWrapper>
            ) : null}
          </div>
          {links ? (
            <ButtonWrapper>
              {links.map((link) =>
                link ? (
                  !linksAsButtons ? (
                    <LinkTrackEvent
                      type='linkWithIcon'
                      key={link.url}
                      icon='link'
                      ctaText={link.label}
                      contextText={name}
                      href={link.url}
                      {...link}
                    />
                  ) : (
                    <StyledLinkTrackEvent
                      type='button'
                      key={link.url}
                      ctaText={link.label}
                      contextText={name}
                      href={link.url}
                    >
                      {link.label}
                    </StyledLinkTrackEvent>
                  )
                ) : null
              )}
            </ButtonWrapper>
          ) : null}
        </Stack>
      </div>
      {!!image?.url && (
        <div css={imageContainerStyle}>
          <Image
            loader={({ src, width, quality }) => {
              return `${src}?w=${width}&q=${quality || 99}&fm=webp`
            }}
            alt={image.description}
            src={image.url}
            fill
            style={{ objectFit: 'contain' }}
            sizes={`(min-width: ${breakpoints.xl}px) 588px, (min-width: ${breakpoints.lg}px) 468px,  (min-width: ${breakpoints.xs}px) 50vw, 100vw`}
          />
        </div>
      )}
    </Grid>
  )
}

export default MainComponent
